import { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import Image from 'next/image'
import { useGlobalData } from 'data'
import { useInView } from 'hooks'
import { getMetricValueByKey, makeMillions } from 'utils/actions'
import { NO_DATA_INDICATOR, isInvalidNumber } from 'utils/formatter'
import { LogoImage, Skeleton } from 'components/ui'
import { Title, TitleDescription } from 'components/text'
import { MetricGroup } from 'components/metrics'

import styles from './vsp.module.scss'

export function Vsp({ content = null, apiData = null }) {
    const { data, isLoading } = useGlobalData([
        'total_vsp_aum',
        'total_vsp_wallets',
        'total_vsps',
    ])

    return (
        <div className={styles.vsp}>
            <FloatingIcons icons={content?.vsps} />

            <div className={styles.hero}>
                <div className={[styles.container, 'container'].join(' ')}>
                    <div className={styles.treatment}>
                        <div className={styles.vspTag}>
                            <span className='icon icon-og icon-vsp-extended' />
                        </div>

                        <Title>
                            Verified Staking
                            <br />
                            Provider Program
                        </Title>
                        <TitleDescription large>
                            The Standard for Trust and Transparency in Staking.
                        </TitleDescription>
                    </div>

                    <div className={styles.right}>
                        {content?.heroStats && (
                            <div className={styles.stats}>
                                <MetricGroup
                                    metricGroup={{
                                        key: 'total_vsp_aum',
                                        label: 'VSP AUM',
                                        prefix: '$',
                                        showPlus: false,
                                        withPercentage: true,
                                        withColor: true,
                                    }}
                                    item={data}
                                    isLoading={isLoading}
                                    timeframeKey={'30d'}
                                    highlighted
                                    large
                                />
                                <MetricGroup
                                    metricGroup={{
                                        key: 'total_vsp_wallets',
                                        label: 'VSP Stakers',
                                        showPlus: false,
                                        withPercentage: true,
                                        withColor: true,
                                    }}
                                    item={data}
                                    isLoading={isLoading}
                                    timeframeKey={'30d'}
                                    highlighted
                                    large
                                />
                            </div>
                        )}

                        <div className={styles.buttons}>
                            <a
                                href={content?.globalLinks?.viewAllUrl || ''}
                                className={[styles.btn, styles.btnBlue].join(
                                    ' '
                                )}
                            >
                                View All
                            </a>
                            <a
                                className={[styles.btn].join(' ')}
                                href={
                                    content?.globalLinks?.getVerifiedUrl || ''
                                }
                            >
                                Get Verified
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* end hero */}

            <div className={styles.features}>
                <div className={[styles.container, 'container'].join(' ')}>
                    <div className={styles.feature}>
                        <span className={styles.label}>
                            {content?.features?.first?.title || ''}
                        </span>
                        <p>{content?.features?.first?.content || ''}</p>
                    </div>
                    <div className={styles.feature}>
                        <span className={styles.label}>
                            {content?.features?.second?.title || ''}
                        </span>
                        <p>{content?.features?.second?.content || ''}</p>
                    </div>
                    <div className={styles.feature}>
                        <div className={styles.labelWrap}>
                            <p className={styles.label}>
                                <span>
                                    {isLoading ? (
                                        <Skeleton
                                            width={'32px'}
                                            height={'24px'}
                                            borderRadius={'4px'}
                                        />
                                    ) : (
                                        getMetricValueByKey(
                                            data,
                                            'total_vsps'
                                        ) ?? ''
                                    )}
                                </span>
                                {'  '}
                                Verified Providers
                            </p>
                        </div>
                        <p>
                            <span>
                                {isLoading ? (
                                    <Skeleton
                                        width={'32px'}
                                        height={'24px'}
                                        borderRadius={'4px'}
                                    />
                                ) : (
                                    getMetricValueByKey(data, 'total_vsps') ??
                                    ''
                                )}
                            </span>
                            {'  '}
                            Providers Have Joined the Program to Make Staking
                            Safer for All.
                        </p>
                    </div>
                </div>
            </div>
            {/* end features */}

            <div className={styles.madeEasy}>
                <div className={[styles.container, 'container'].join(' ')}>
                    <Rows data={apiData} />
                    <div className={styles.gradient}>
                        <Image
                            src='/static/img/conic-gradient.png'
                            alt='gradient'
                            className={styles.img}
                            fill
                            priority
                            sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
                        />
                    </div>

                    <div className={styles.media}>
                        <RotatingIcons icons={content?.vsps} />
                    </div>

                    <div className={styles.treatment}>
                        <h2
                            dangerouslySetInnerHTML={{
                                __html: content?.stakingMadeEasy?.title,
                            }}
                        />
                        <p>{content?.stakingMadeEasy?.content || ''}</p>
                    </div>
                </div>
            </div>

            <div className={styles.safeStaking}>
                <div className={[styles.container, 'container'].join(' ')}>
                    <div className={styles.treatment}>
                        <h2>{content?.makeStakingSafe?.title || ''}</h2>
                        <p>{content?.makeStakingSafe?.content || ''}</p>
                    </div>
                </div>
            </div>

            <div className={styles.industryStandard}>
                <div className={[styles.container, 'container'].join(' ')}>
                    <div className={styles.treatment}>
                        <h2>{content?.industryStandard?.title || ''}</h2>
                        <p>{content?.industryStandard?.content || ''}</p>
                    </div>
                </div>
            </div>

            <div className={styles.methodologies}>
                <div className='container'>
                    <div className={styles.header}>
                        <h2>Our Rating Methodologies</h2>
                        <p>A rating system for safe and reliable staking.</p>
                    </div>

                    <div className={styles.srRating}>
                        <div className={styles.ratingHeader}>
                            <h3>{content?.srRating?.title || ''}</h3>
                            <p>{content?.srRating?.subtitle || ''}</p>
                        </div>
                        {Boolean(content?.srRating?.features?.length) && (
                            <div className={styles.ratingFeatures}>
                                {content?.srRating?.features?.map((el, i) => (
                                    <div
                                        className={styles.ratingFeature}
                                        key={i}
                                    >
                                        <span
                                            className={[
                                                'icon icon-og icon-vsp',
                                                styles.vspTag,
                                            ].join(' ')}
                                        />
                                        {el?.feature || ''}
                                    </div>
                                ))}
                            </div>
                        )}
                        <div className={styles.ratingContent}>
                            {content?.srRating?.content || ''}
                        </div>
                        <a
                            className={styles.btn}
                            href={content?.srRating?.learnMoreUrl || ''}
                        >
                            Learn More
                        </a>
                    </div>

                    <div className={styles.valueRating}>
                        <div className={styles.ratingHeader}>
                            <h3>{content?.valueAddedRating?.title || ''}</h3>
                            <p>{content?.valueAddedRating?.subtitle || ''}</p>
                        </div>

                        {Boolean(
                            content?.valueAddedRating?.features?.length
                        ) && (
                            <div className={styles.ratingFeatures}>
                                {content?.valueAddedRating?.features?.map(
                                    (el, i) => (
                                        <div
                                            className={styles.ratingFeature}
                                            key={i}
                                        >
                                            <span
                                                className={[
                                                    'icon icon-og icon-vsp',
                                                    styles.vspTag,
                                                ].join(' ')}
                                            />
                                            {el?.feature || ''}
                                        </div>
                                    )
                                )}
                            </div>
                        )}

                        <div className={styles.ratingContent}>
                            {content?.valueAddedRating?.content || ''}
                        </div>
                        <a
                            className={styles.btn}
                            href={content?.valueAddedRating?.learnMoreUrl || ''}
                        >
                            Learn More
                        </a>
                    </div>
                </div>
            </div>
            {/* end methodologies */}

            <div className={styles.diligenceMonitoring}>
                <div className='container'>
                    <div className={styles.diligence}>
                        <div className={styles.treatment}>
                            <h2>{content?.dueDiligence?.title || ''}</h2>
                            <p>{content?.dueDiligence?.content || ''}</p>
                        </div>
                    </div>
                    <div className={styles.monitoring}>
                        <div className={styles.treatment}>
                            <h2>{content?.monitoring?.title || ''}</h2>
                            <p>{content?.monitoring?.content || ''}</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* end diligence and monitoring */}

            {Boolean(content?.trustedBy?.length) && (
                <div className={styles.trustedBy}>
                    <div className='container'>
                        {content?.trustedBy?.map((el, i) => (
                            <div className={styles.logo} key={i}>
                                <Image
                                    src={
                                        el?.sourceUrl || '/static/img/black.png'
                                    }
                                    alt={el?.title || `Trusted by ${i}`}
                                    className={styles.img}
                                    fill
                                    sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {content?.marketLeading && (
                <div className={styles.marketLeading}>
                    <div className='container'>
                        <div className={styles.treatment}>
                            <h2>{content?.marketLeading?.title || ''}</h2>
                            <p>{content?.marketLeading?.content || ''}</p>
                            <a
                                href={
                                    content?.marketLeading?.learnMoreUrl || ''
                                }
                                className={styles.btn}
                            >
                                Learn More
                            </a>
                        </div>
                    </div>
                </div>
            )}
            {/* end market leading */}

            {Boolean(content?.testimonials?.length) && (
                <div className={styles.testimonials}>
                    <div className={styles.gradient}>
                        <Image
                            src='/static/img/conic-gradient.png'
                            alt='gradient'
                            className={styles.img}
                            fill
                            sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
                        />
                    </div>
                    <h2>Trusted by Industry Leaders</h2>
                    <div className={styles.testimonialWrap}>
                        {content?.testimonials?.map((el, i) => {
                            const person = el?.testimonial?.person
                            return (
                                <div className={styles.testimonial} key={i}>
                                    <div className={styles.content}>
                                        <span className={styles.triangle} />
                                        {el?.testimonial?.content || ''}
                                    </div>
                                    <div className={styles.details}>
                                        <div className={styles.user}>
                                            <LogoImage
                                                src={person?.sourceUrl}
                                                alt={
                                                    person?.title ||
                                                    `Testimonial ${i}`
                                                }
                                                className={styles.img}
                                                slug={String(
                                                    person?.title
                                                ).replaceAll(' ', '_')}
                                                size={60}
                                            />
                                        </div>
                                        <div className={styles.title}>
                                            {person?.title || ''}
                                        </div>
                                        <div className={styles.role}>
                                            {person?.media_options?.role || ''}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )}
            {/* end testimonials */}

            <div className={styles.closing}>
                <div className='container'>
                    <div className={styles.treatment}>
                        <span
                            className={[
                                styles.icon,
                                'icon icon-og icon-vsp-extended',
                            ].join(' ')}
                        />
                        <h2>{content?.closing?.title || ''}</h2>
                        <p>{content?.closing?.content || ''}</p>
                        <div className={styles.buttons}>
                            <a
                                href={content?.globalLinks?.viewAllUrl || ''}
                                className={[styles.btn, styles.btnBlue].join(
                                    ' '
                                )}
                            >
                                View All
                            </a>
                            <a
                                href={
                                    content?.globalLinks?.getVerifiedUrl || ''
                                }
                                className={styles.btn}
                            >
                                Get Verified
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* end market leading */}
        </div>
    )
}

export function FloatingIcons({
    className = '',
    icons = [],
    withGradient = true,
}) {
    return (
        <div
            className={classNames(styles.floatingIcons, {
                [styles.withGradient]: withGradient,
            })}
        >
            <div className={styles.move}>
                <div className={[styles.icons, className].join(' ')}>
                    {[...new Array(2)].map((el, i) => (
                        <div className={styles.iconGroup} key={i}>
                            {icons.map((el, i) => {
                                const even = icons.length % 2 === 0
                                const last = i === icons.length - 1

                                if (!even && last) return null

                                return (
                                    <div className={styles.iconWrap} key={i}>
                                        <div className={styles.iconInner}>
                                            <LogoImage
                                                src={el?.sourceUrl}
                                                alt={el?.title || `Icon${i}`}
                                                className={styles.img}
                                                slug={String(el?.title).replace(
                                                    / /g,
                                                    '_'
                                                )}
                                                size={80}
                                            />
                                        </div>
                                        <span
                                            className={[
                                                'icon icon-og icon-vsp',
                                                styles.vspTag,
                                            ].join(' ')}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

function RotatingIcons({ icons = [] }) {
    return (
        <div className={styles.rotatingIcons}>
            <div className={[styles.icon, styles.iconCenter].join(' ')}>
                <span className={'icon icon-og icon-vsp'} />
            </div>
            {[
                styles.iconFirst,
                styles.iconSecond,
                styles.iconThird,
                styles.iconFourth,
                styles.iconFifth,
                styles.iconSixth,
            ].map((iconClass, idx) => {
                const icon = icons?.[idx] ?? {}
                return (
                    <div
                        key={idx}
                        className={[styles.icon, iconClass].join(' ')}
                    >
                        <LogoImage
                            src={icon?.sourceUrl}
                            alt={icon?.title || `Provider icon${idx + 1}`}
                            className={styles.img}
                            size={56}
                            slug={String(icon?.title).replaceAll(' ', '_')}
                        />
                        <span className={'icon icon-og icon-vsp'} />
                    </div>
                )
            })}
        </div>
    )
}

function Rows({ data = null }) {
    const rowsRef = useRef(null)
    const inView = useInView(rowsRef)
    const [play, setPlay] = useState(false)

    useEffect(() => {
        if (inView) {
            setPlay(true)
        }
    }, [inView])

    return (
        Boolean(data?.providers?.length) && (
            <div className={styles.rows} ref={rowsRef} data-in-view={play}>
                {data?.providers?.map((el, i) => (
                    <div className={styles.rowWrap} key={i}>
                        <Row data={el} />
                    </div>
                ))}
            </div>
        )
    )
}

function Row({ data = null }) {
    const aum = data?.metrics?.find(
        el => el?.metricKey === 'assets_under_management'
    )
    const stakers = data?.metrics?.find(
        el => el?.metricKey === 'staking_wallets'
    )
    const commission = data?.metrics?.find(el => el?.metricKey === 'commission')

    const changePercentages = obj => JSON.parse(obj?.changePercentages ?? null)
    const changeAbsolutes = obj => JSON.parse(obj?.changeAbsolutes ?? null)

    const netStakingFlow = changeAbsolutes(aum)?.['7d']?.toFixed(2)

    return (
        <div className={styles.row}>
            <div
                className={['icon icon-og icon-vsp', styles.vspTag].join(' ')}
            />
            <div className={styles.providerGroup}>
                <div className={styles.icon}>
                    <LogoImage
                        src={data?.logoUrl}
                        alt={data?.name || 'Provider'}
                        className={styles.img}
                        slug={data?.slug}
                        size={36}
                    />
                </div>
                <div className={styles.name}>{data?.name || ''}</div>
            </div>
            <RowMetric
                label='AuM'
                value={
                    aum?.defaultValue
                        ? makeMillions(aum?.defaultValue)
                        : NO_DATA_INDICATOR
                }
                changeValue={changePercentages(aum)?.['7d']}
                showChange={true}
            />
            <RowMetric
                label='Net Staking Flow 7d'
                value={
                    !isInvalidNumber(netStakingFlow)
                        ? `${netStakingFlow > 0 ? '+' : ''}${makeMillions(
                              netStakingFlow
                          )}`
                        : NO_DATA_INDICATOR
                }
                changeValue={netStakingFlow}
            />
            <RowMetric
                label='Stakers'
                value={
                    stakers?.defaultValue
                        ? stakers?.defaultValue?.toLocaleString('en-US')
                        : NO_DATA_INDICATOR
                }
                changeValue={changePercentages(stakers)?.['7d']}
                showChange={true}
            />
            <RowMetric
                label='Commission'
                value={
                    commission?.defaultValue
                        ? commission?.defaultValue?.toLocaleString('en-US', {
                              maximumFractionDigits: 2,
                          }) + '%'
                        : NO_DATA_INDICATOR
                }
                changeValue={changeAbsolutes(commission)?.['7d']}
                showChange={true}
            />
        </div>
    )
}

function RowMetric({
    className = '',
    label,
    value,
    changeValue,
    showChange = false,
    customColor = '',
}) {
    return (
        <div className={[styles.rowMetric, className].join(' ')}>
            <div className={styles.label}>
                <span
                    className={styles.changeIndicator}
                    data-neg={!isInvalidNumber(changeValue) && changeValue < 0}
                    data-custom-color={customColor}
                />
                {label}
            </div>
            <div className={styles.values}>
                <span className={styles.value}>
                    {value || NO_DATA_INDICATOR}
                </span>
                {showChange && !isInvalidNumber(changeValue) && (
                    <span className={styles.change}>
                        {changeValue > 0 && '+'}
                        {changeValue?.toFixed(2)}%
                    </span>
                )}
            </div>
        </div>
    )
}
